import { Content } from '@/types/content';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { findAllOfType } from 'lib/labrador/content-operations/findBy';
import { removeBySelectors } from 'lib/labrador/content-operations/removeBy';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppState } from './useAppState';

export const useInfinityScrollFront = ({
  sourceRoute,
  pagesToAutoLoad = 2,
  pageData,
  intersectionTargetElement,
}: {
  sourceRoute: string;
  pagesToAutoLoad: number;
  pageData: Content;
  intersectionTargetElement: RefObject<HTMLDivElement>;
}) => {
  const [{ device }] = useAppState();
  const [loading, setLoading] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const [loadedPages, setLoadedPages] = useState<Content[]>([]);

  const loadNextPage = useCallback(async () => {
    const url = getUrl('/api/page');
    if (!url || loading || endReached) return;

    setLoading(true);

    const params = new URLSearchParams({
      route: sourceRoute,
      exclude: findAllOfType([...pageData.children, ...loadedPages], 'articleTeaser')
        .map((article) => article.meta.articleId)
        .join(','),
      device,
    });

    url.search = params.toString();
    const response = await http.get<Content>(url.href);
    const data = response?.data;

    if (data) {
      data.children = removeBySelectors(['highlightedArticles', 'trendingMenu'], data.children);
      setLoadedPages((prevLoadedPages) => [...prevLoadedPages, data]);
    }

    setEndReached(!data || data?.children?.length === 0);

    setLoading(false);
    return data;
  }, [pageData, loadedPages, sourceRoute, device, loading, endReached]);

  useEffect(() => {
    if (!intersectionTargetElement.current || loadedPages.length >= pagesToAutoLoad) return;

    const observer = new IntersectionObserver(([entry]) => {
      const isIntersecting = entry.isIntersecting;
      if (isIntersecting && !loading) {
        loadNextPage().then((data) => !data?.children?.length && observer.disconnect());
      }
    });
    observer.observe(intersectionTargetElement.current as Element);

    return () => observer.disconnect();
  }, [loadedPages, loading, intersectionTargetElement, loadNextPage, pagesToAutoLoad]);

  return useMemo(
    () => ({ loading, pages: loadedPages, loadNextPage, endReached }),
    [loading, loadedPages, loadNextPage, endReached],
  );
};
