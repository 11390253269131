import type { LinkProps } from '@/components/Link';
import type { StandaloneComponent } from '@/types/component';

import { Link } from '@/components/Link';

export interface StandaloneOptionalLinkProps extends LinkProps {
  children?: React.ReactNode;
}

export const StandaloneOptionalLink: StandaloneComponent<StandaloneOptionalLinkProps> = ({ children, ...props }) => {
  return <Link content={children} {...props} isOptional />;
};
